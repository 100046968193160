import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin: 5rem 0;
    text-align: center;
`;

const Loading = () => (
    <Wrapper>Loading...</Wrapper>
);

export default Loading;